import {Box, Heading} from "@chakra-ui/react";
import {Link} from "react-router-dom";

export default function Cookies()
{
    const Owner = () => (
        <>
            <strong>Asociatia Civica Iar Miroase</strong>
        </>
    );

    const Website = () => (
        <strong>https://www.iarmiroase.ro</strong>
    );

    return (
        <Box boxShadow="md" bg="gray.100" rounded={4} p={4}>
            <Heading size="2xl">Politică privind fișierele cookies</Heading>
            <Heading size="lg" mt={4}>1. Introducere</Heading>
            <p>Prezenta Politică privind fișierele cookies se aplică tuturor utilizatorilor paginii de internet <Website />. Informațiile prezentate în continuare au ca
                scop informarea utilizatorilor acestei pagini de internet cu privire la plasarea, utilizarea și
                administrarea cookie-urilor de către <Owner /> în
                contextul navigării utilizatorilor pe această pagină de internet.</p>
            <Heading size="lg" mt={4}>2. Ce sunt cookie-urile?</Heading>
            <p>Folosim termenul „cookie”-uri pentru a ne referi la modulele cookie și la tehnologiile similare prin
                intermediul cărora pot fi colectate informații în mod automat.<br />
                    Un „Internet Cookie” (termen cunoscut și sub denumirea de „browser cookie” sau „HTTP cookie” ori
                    „cookie”) reprezintă un fișier de mici dimensiuni, format din litere și numere, care va fi stocat pe
                    computerul, terminalul mobil sau pe alte echipamente ale unui utilizator prin intermediul cărora se
                    accesează internetul.<br />
                    Cookie-urile sunt instalate prin solicitarea emisă de un web-server către un browser (de ex.:
                    Internet Explorer, Firefox, Chrome). Cookie-urile odată instalate au o durată de existență
                    determinată, rămânând „pasive”, în sensul că nu conțin programe software, viruși sau spyware și nu
                    vor accesa informațiile de pe hard driverul utilizatorului pe al cărui echipament au fost instalate.<br />
                    Un cookie este format din două părți:<br />
                    numele cookie-ului; și<br />
                    conținutul sau valoarea cookie-ului.<br />
                    Din punct de vedere tehnic, doar web-serverul care a trimis cookie-ul îl poate accesa din nou în
                    momentul în care un utilizator se întoarce pe pagina de internet asociată web-serverului respectiv.
            </p>
            <Heading size="lg" mt={4}>3. Pentru ce scopuri sunt utilizate cookie-urile prin intermediul acestei pagini de internet:</Heading>
            <p>Cookie-urile sunt utilizate pentru a furniza utilizatorilor acestei pagini de internet o experiență mai
                bună de navigare și servicii adaptate nevoilor și interesului fiecărui utilizator în parte și anume
                pentru:</p>
            <ul style={{paddingLeft: "25px"}}>
                <li>îmbunătățirea utilizării acestei pagini de internet, inclusiv prin identificarea oricăror erori care
                    apar în timpul vizitării/utilizării acesteia de către utilizatori;
                </li>
                <li>furnizarea de statistici anonime cu privire la modul în care este utilizată această pagină de
                    internet către <Owner />, în calitate de
                    deținător al acestei pagini de internet;
                </li>
                <li>anticiparea unor eventuale bunuri care vor fi în viitor puse la dispoziția utilizatorilor prin
                    intermediul acestei pagini de internet, în funcție de serviciile / produsele accesate.
                </li>
            </ul>
            <Heading size="lg" mt={4}>4. Care este durata de viață a cookie-urilor?</Heading>
            <p>Durata de viață a cookie-urilor poate varia semnificativ, depinzând de scopul pentru care este plasat.
                Există următoarele categorii de cookie-uri care determină și durata de viață a acestora:</p>
            <ul>
                <li><strong>Cookie-uri de sesiune</strong> – Un „cookie de sesiune” este un cookie care este șters
                    automat când utilizatorul își închide browserul.
                </li>
                <li><strong>Cookie-uri persistente sau fixe</strong> – Un „cookie persistent” sau „fix” este un cookie
                    care rămâne stocat în terminalul utilizatorului până când atinge o anumită dată de expirare (care
                    poate fi în câteva minute, zile sau câțiva ani în viitor) sau până la ștegerea acestuia de către
                    utilizator în orice moment prin intermediul setărilor browserului.
                </li>
            </ul>
            <Heading size="lg" mt={4}>5. Ce sunt cookie-urile plasate de terți?</Heading>
            <p>Anumite secțiuni de conținut de pe pagina de internet pot fi furnizate prin intermediul unor terți, adică
                nu de către <Owner />, caz în care aceste
                cookie-uri sunt denumite cookie-uri plasate de terți („third party cookie-uri”).<br />
                    Terții furnizori ai cookie-urilor trebuie să respecte, de asemenea, regulile în materie de protecție
                a datelor și <Link to="/confidentialitate">Politica de Confidențialitate</Link> disponibilă pe această pagină de internet.<br />
                    Aceste cookie-uri pot proveni de la urmatorii terți: Google, Facebook.</p>
            <Heading size="lg" mt={4}>6. Ce cookie-uri sunt folosite prin intermediul acestei pagini de internet:</Heading>
            <p>Prin utilizarea/vizitarea paginii de internet pot fi plasate următoarele cookie-uri:
            </p>
            <p style={{ paddingLeft: '30px'}}>a. Cookie-uri de performanță a paginii de internet;<br />
                b. Cookie-uri de analiză a utilizatorilor;<br />
                c. Cookie-uri de înregistrare;</p>
            <Heading size="md">a. Cookie-uri de performanță</Heading>
            <p>Prin acest tip de cookie-uri sunt memorate preferințele utilizatorului acestei pagini de internet, astfel
                încât setarea din nou a preferințelor în cazul vizitării ulterioare a paginii de internet nu mai este
                necesară.</p>
            <Heading size="md">b. Cookie-uri de analiză a utilizatorilor</Heading>
            <p>Aceste cookie-uri ne informează dacă un anumit utilizator al paginii de internet a mai vizitat/utilizat
                această pagină de internet anterior. Aceste cookie-uri sunt utilizate doar în scopuri statistice.</p>
            <Heading size="md">c. Cookie-uri pentru înregistrare</Heading>
            <p>Atunci când vă înregistrați pe acest site, se generează cookie-uri care memorează acest demers. Serverele
                utilizează aceste cookie-uri pentru a ne arăta contul cu care sunteți înregistrat. De asemenea,
                utilizarea acestor cookie-uri permite să asociem orice comentariu postat pe pagina de internet cu
                username-ul contului folosit. În cazul în care nu a fost selectată opțiunea „păstrează-mă înregistrat”,
                aceste cookie-uri se vor șterge automat la momentul terminării sesiunii de navigare.</p>
            <Heading size="lg" mt={4}>7. Ce tip de informații sunt stocate și accesate prin intermediul cookie-urilor?</Heading>
            <p>Cookie-urile păstrează informații într-un fișier text de mici dimensiuni care permit recunoașterea
                browserului. Această pagină de internet recunoaște browserul până când cookie-urile expiră sau sunt
                șterse.</p>
            <Heading size="lg" mt={4}>8. Particularizarea setările browserului în ceea ce privește cookie-urile</Heading>
            <p>În cazul în care utilizarea cookie-urilor nu este deranjantă iar calculatorul sau echipamentul tehnic
                utilizat pentru navigarea pe această pagină de internet este folosit doar de către dumneavoastră, pot fi
                setate termene lungi de expirare pentru stocrarea istoricului de navigare.<br />
                    În cazul în care calculatorul sau echipamentul tehnic utilizat pentru navigarea pe această pagină de
                    internet este folosit de mai multe persoane, poate fi luată în considerare setarea pentru ștergerea
                    datelor individuale de navigare de fiecare dată când browserul este închis.</p>
            <Heading size="lg" mt={4}>9. Cum pot fi oprite cookie-urile?</Heading>
            <p>Dezactivarea și refuzul de a primi cookie-uri pot face această pagină de internet dificil de vizitat,
                atrăgând după sine limitări ale posibilităților de utilizare ale acesteia.<br />
                    Utilizatorii își pot configura browserul să respingă fișierele cookie sau să fie acceptate
                    cookie-uri de la o pagină de internet anume.<br />
                    Toate browserele moderne oferă posibilitatea de a schimba setările cookie-urilor. Aceste setări pot
                    fi accesate, ca regulă, în secțiunea „opțiuni” sau în meniul de „preferințe” al browserului tău.<br />
                    Totuși, refuzarea sau dezactivarea cookie-urilor nu înseamnă că nu veți mai primi publicitate online
                    – ci doar ca aceasta nu va fi adaptată preferințelor și interesele dumneavoastră, evidențiate prin
                    comportamentul de navigare.</p>
            <p>Pentru a înțelege aceste setări, următoarele linkuri pot fi folositoare:</p>
            <ul style={{ paddingLeft: "25px" }}>
                <li><a
                    href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                    target="_blank" rel="noopener">Cookie settings in Internet Explorer</a></li>
                <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                       target="_blank" rel="noopener">Cookie settings in Firefox</a></li>
                <li><a href="https://support.google.com/chrome/answer/95647" target="_blank" rel="noopener">Cookie
                    settings in Chrome</a></li>
                <li><a href="https://support.apple.com/kb/ph21411" target="_blank" rel="noopener">Cookie settings in
                    Safari</a></li>
            </ul>
            <p>Pentru orice întrebări suplimentare cu privire la modul în sunt utilizate cookie-urile prin intermediul
                acestei pagini de internet, vă rugăm să vă adresați la: iarmiroase@gmail.com / iarmiroase@galberceni.ro             </p>
        </Box>
    );
}
