import {Box, Center, Image, Text} from "@chakra-ui/react";
import LogoAsociatie from "../assets/logo-gal-berceni.png";
import React from "react";

const About = () => (
        <Box boxShadow="md" bg="gray.100" rounded={4} p={4}>
            <Text fontSize="sm" mb={4}><strong>Iar Miroase</strong> este un proiect web care ajuta
                locuitorii din zonele poluate din toata tara, sa
                sesiseze organele abilitate (Garda de Mediu, Directia de Sanatate Publica)
                de fiecare data cand constanta abateri.</Text>

            <Text fontSize="sm" mb={4}>Datele prelucrate sunt folosite strict in scopul mai sus mentionat,
                iar daca din orice motiv va doriti stergerea datelor, va rugam sa ne solicitati acest aspect
                la adresa de email <strong>contact@iarmiroase.ro</strong></Text>

            <Text fontSize="sm" mb={4}>Tot la adresa <strong>contact@iarmiroase.ro</strong> ne puteti scrie
                in legatura cu aspecte care nu functioneaza in cadrul aplicatiei, sau eventuale
                sugestii.</Text>

            <Text fontSize="sm" mb={4}>Un proiect dezvoltat de <strong>Asociatia Civica Iar Miroase (CIF: 49465734)</strong></Text>
        </Box>
    )
;

export default About;
