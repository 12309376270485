import config from "./config/config";
import {useEffect, useState} from "react";
import {useReport} from "./utils/contexts/ReportContext";
import {Sensor, sensorData, sensorsGet} from "./api/Sensor";
import {perc2color} from "./utils/general";
import {Box, Flex, Switch, Text} from "@chakra-ui/react"
import {APIProvider, Map, Marker, InfoWindow, AdvancedMarker, MapControl, ControlPosition} from '@vis.gl/react-google-maps';
import { Markers } from "./components/Markers";
import {useUser} from "./utils/hooks/UserProvider";
import { IoAccessibility } from "react-icons/io5";
import configs from "./config/config";
import {formattedReports} from "./utils/map";
import {Link} from "react-router-dom";

type Tree = {
    key: string;
    name: string;
    lat: number;
    lng: number;
};
const ReportMap = (props: { lat?: number; long?: number; }) => {

    const [availableSensors, setAvailableSensors] = useState<{id: string, identifier: string}[]>([]);
    const [sensors, setSensors] = useState<Sensor[]>([]);
    const {coordinates, userData, isAuthenticated} = useUser();

    useEffect(() => {
        if (isAuthenticated) {
            fetch(configs.apiUrl + `/stats/sensors/${userData?.CityId}`)
                // When got a response call a `json` method on it
                .then((response) => response.json())
                // and return the result data.
                .then((data) => data as { id: string, identifier: string }[])
                .then(data => setAvailableSensors(data));
        }
    }, [userData?.CityId, isAuthenticated]);

    useEffect(() => {
        (availableSensors.map(async item => {
            const sensor: Sensor = await sensorData({id: item.id});
            setSensors((old) => [...old, sensor]);
        }))
    }, [availableSensors]);

    const { lastReports } = useReport();

    const containerStyle = {
        width: '100%',
        height: '400px',
        featureType: "poi",
        stylers: [
            { visibility: "off" }
        ],
    };

    const center = {
        lat: props.lat ?? 45.869251,
        lng: props.long ?? 24.755846
    };

    const [selectedElement, setSelectedElement] = useState<number>(-1);

    const [showReports, setShowReports] = useState<boolean>(true);
    const [showMe, setShowMe] = useState<boolean>(false);
    const [showSensors, setShowSensors] = useState<boolean>(true);

    return (
        <APIProvider apiKey={config.google.mapsApiKey}>
            <Map
                center={center}
                zoom={props.lat && props.long ? 12 : 6.09}
                disableDefaultUI={true}
                style={
                    containerStyle
                }
                mapId="13005f17cf8efbaf"
                mapTypeId="roadmap"
            >
                <>
                    {lastReports.length && <Markers points={showReports ? formattedReports(lastReports) : []} />}
                    {showSensors && sensors.length && sensors.map((sensor, index) => (
                        <AdvancedMarker position={{lat: sensor.latitude, lng: sensor.longitude}} onClick={() => setSelectedElement(index)}>
                            <Box border={`1px solid ${perc2color(sensor.perc + 5)}`} boxShadow='md' style={{ background: perc2color(sensor.perc), width: "16px", height: "16px", borderRadius: "50%" }}></Box>
                            {sensor.gas1.toFixed(2)}
                        </AdvancedMarker>
                    ))}
                    {coordinates && showMe && (
                        <AdvancedMarker position={{lat: parseFloat(coordinates.lat as string), lng: parseFloat(coordinates.long as string)}}>
                            <IoAccessibility fontSize="30px" />
                        </AdvancedMarker>
                    )}
                    {selectedElement >= 0 && (
                        <InfoWindow position={{lat: sensors[selectedElement].latitude + 0.00002, lng: sensors[selectedElement].longitude}} onCloseClick={() => setSelectedElement(-1)}>
                            <Text fontSize={10}>
                                H2S pe ultimele 30 de minute: {sensors[selectedElement].gas1.toFixed(3)} ug/m3.<br />Limita maxima admisa: 0.015 ug/m3<br/>Valoarea curenta: {sensors[selectedElement].now.toFixed(3)}
                            </Text>
                            <Text fontSize={9}>
                                Sursa date: <Link to={selectedElement.source}>Urad Monitor</Link>
                            </Text>
                        </InfoWindow>
                    )}
                </>
                <MapControl position={ControlPosition.TOP_LEFT}>
                    <Flex p={1} m={1} bg="white" rounded="md" boxShadow="base" flex="1" flexDirection="column" gap="1">
                        <Box><Switch isChecked={showReports} onChange={(value) => setShowReports(value.currentTarget.checked)} size='sm' /> Ascunde sesizarile</Box>
                        {isAuthenticated && (<Box><Switch isChecked={showMe} onChange={(value) => setShowMe(value.currentTarget.checked)} size='sm' /> Ascunde-ma pe harta</Box>)}
                        {!!availableSensors.length && (<Box><Switch isChecked={showSensors} onChange={(value) => setShowSensors(value.currentTarget.checked)} size='sm' /> Ascunde senzorii</Box>)}
                    </Flex>
                </MapControl>
            </Map>
        </APIProvider>
    );
};

export default ReportMap
