import {Box, Heading} from "@chakra-ui/react";
import {Link} from "react-router-dom";

export default function Policy()
{
    const Owner = () => (
        <>
            <strong>Asociatia Civica Iar Miroase</strong>
        </>
    );

    const Website = () => (
        <strong>https://www.iarmiroase.ro</strong>
    );

    return (
        <Box boxShadow="md" bg="gray.100" rounded={4} p={4}>
            <div className="post-content">
                <Heading size="xl">Politică de confidențialitate</Heading>
                <Heading size="lg" mt={4}>I. Informații generale</Heading>
                <p>Confidențialitatea datelor dumneavoastră cu caracter personal reprezintă una dintre preocupările
                    principale ale <Owner />, CIF: 37139270, Reg. Asociatiilor: 22806/A/2017 , cu sediul în Vidra, Judetul Ilfov, în calitate de operator de
                    date.<br />
                        Acest document are rolul de a vă informa cu privire la prelucrarea datelor dumneavoastră cu
                        caracter personal, în contextul utilizării paginii de internet <Website />.
                        („Site-ul”)</p>
                <Heading size="lg" mt={4}>II. Categoriile de date cu caracter personal prelucrate</Heading>
                <p>
                    <strong>II.1.</strong> Dacă sunteți client al Site-ului, <Owner /> va
                    prelucra datele dumneavoastră cu caracter personal, cum ar fi nume şi prenume, telefon, adresa de
                    e-mail, adresa de resedinta, date referitoare la modul în care utilizați Site-ul,
                    de exemplu comportamentul/preferinţele/obişnuințele dumneavoastră în cadrul <Owner />, precum și orice alte categorii de
                    date pe care le furnizați în mod direct în contextul creării contului de utilizator.</p>
                <p>Dacă pentru a vă crea cont de utilizator pe Site, utilizați contul dumneavoastră de Facebook sau
                    Google, <Owner /> va prelucra următoarele date
                    publice de profil afişate de aplicaţiile respective: nume utilizator, adresa de e-mail, imagine de avatar.</p>
                <p><strong>II.2.</strong> Dacă sunteți vizitator al Site-ului, <Owner /> va
                    prelucra datele dumneavoastră cu caracter personal pe care le furnizați în mod direct în contextul
                    utilizării Site-ului, cum ar fi datele pe care le furnizați în cadrul secțiunii de contact /
                    întrebări / reclamații, în măsura în care ne contactați în acest fel.</p>
                <Heading size="lg" mt={4}>III. Scopurile și temeiurile de prelucrării</Heading>
                <p>Dacă sunteți client al Site-ului,<Owner /> prelucrează
                    datele dumneavoastră cu caracter personal astfel pentru a va facilita trimiterea sesizarilor de fiecare data.</p>
                <Heading size="lg" mt={4}>IV. Durata pentru care vă prelucrăm datele</Heading>
                <p>Ca principiu, <Owner /> va prelucra datele
                    dumneavoastră cu caracter personal atât cât este necesar pentru realizarea scopurilor de prelucrare
                    menționate mai sus.</p>
                <p>Daca din orice motiv doriti ca datele sa nu mai fie prelucrate/stocate de catre <Owner /> va rugam sa solicitati stergerea acestora printr-un email la adresa iarmiroase@gmail.com</p>
                <Heading size="lg" mt={4}>V. Dezvăluirea datelor cu caracter personal</Heading>
                <p><Owner /> nu va dezvalui datele cu caracter personal si le va folosi strict in realizarea scopului mentionat anterior.</p>
                <Heading size="lg" mt={4}>VI. Transferul datelor cu caracter personal</Heading>
                <p>Datele cu caracter personal furnizate către <Owner /> pot fi transferate în afara României, dar
                    doar către state din Uniunea Europeană.</p>
                <Heading size="lg" mt={4}>VII. Drepturile de care beneficiați</Heading>
                <p>În condițiile prevăzute de legislația în materia prelucrării datelor cu caracter personal, în
                    calitate de persoane vizate, beneficiați de următoarele drepturi:</p>
                <ul style={{ paddingLeft: "25px" }}>
                    <li>dreptul la informare, respectiv dreptul de a primi detalii privind activitățile de prelucrare
                        efectuate de către <Owner />, conform
                        celor descrise în prezentul document;
                    </li>
                    <li>dreptul de acces la date, respectiv dreptul de a obține confirmarea din partea <Owner /> cu privire la prelucrarea datelor cu
                        caracter personal, precum și detalii privind activitățile de prelucrare precum modalitatea în
                        care sunt prelucrate datele, scopul în care se face prelucrarea, destinatarii sau categoriile de
                        destinatari ai datelor, etc;
                    </li>
                    <li>dreptul la rectificare, respectiv dreptul de a obține corectarea, fără întârzieri justificate,
                        de către <Owner /> a datelor cu caracter
                        personal inexacte/ nejustificate, precum și completarea datelor incomplete; Rectificarea/
                        completarea va fi comunicată fiecărui destinatar la care au fost transmise datele, cu excepția
                        cazului în care acest lucru se dovedește imposibil sau presupune eforturi disproporționate.
                    </li>
                    <li>dreptul la ștergerea datelor, fără întârzieri nejustificate, („dreptul de a fi uitat”)
                    </li>
                </ul>
                <p style={{ margin: "15px 0px" }}><strong>Este posibil ca, în urma solicitării de ștergere a datelor, <Owner /> să anonimizeze aceste date (lipsindu-le
                    astfel de caracterul personal) și să continue în aceste condiții prelucrarea pentru scopuri
                    statistice;</strong></p>
                <p>Pentru orice întrebări suplimentare cu privire la modul în care datele cu caracter personal sunt
                    prelucrate și pentru a vă exercita drepturile menționate mai sus, vă rugăm să vă adresați la adresa
                    de email: <strong>iarmiroase@gmail.com</strong>.<br />
                        Această pagină de internet folosește fișiere de tip cookie. Pentru mai multe informații cu
                    privire la modul în care se folosesc aceste fișiere, vă rugăm să accesați următorul <Link to="/cookies">link</Link></p>
            </div>
        </Box>
    );
}
